import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, take } from 'rxjs';
import { QuestTeam } from 'src/app/_interface/team.types';
import { environment } from '../../../../../environments/environment';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ui-select-team',
  templateUrl: './ui-select-team.component.html',
  styleUrls: ['./ui-select-team.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiSelectTeamComponent),
      multi: true
    }
  ]
})
export class UiSelectTeamComponent implements OnChanges, ControlValueAccessor {
  @Input() teamsList: QuestTeam[];
  @Input() emitChanges: boolean = false;
  @Output() teamSelect = new EventEmitter();
  @Input() isForDonation: boolean = false;
  @Input() isMegaQuest: boolean = false;
  eventsSubject: Subject<void> = new Subject<void>();
  selectedId: number = null;
  touched = false;
  generalFundDetail: any = {};
  filteredTeamList: QuestTeam[] = [];

  propagateChange = (_: any) => {};

  onTouched: any = () => {
    this.touched = true;
  };

  @ViewChild('ngSelectElem', {static: false}) ngSelectElem: NgSelectComponent;

  @Input('showAsDropdown') showAsDropdown: boolean = false;
  
  @Input('showTeamSelection') showTeamSelection: boolean = false;

  @ViewChild('teamDropdown', {static: false}) teamDropdown: NgbDropdown;

  @Input('formIsSubmitted') formIsSubmitted: boolean = false;

  addInvalidClass: boolean = false;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    if (this.teamsList && this.teamsList.length != 0) {
      this.teamsList.sort((a, b) => {
        if (a.teamName && b.teamName) {
          let textA = a.teamName.toUpperCase();
          let textB = b.teamName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }

        return 1;
      });
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (this.teamDropdown && this.showAsDropdown && this.showTeamSelection) {
      this.teamDropdown.open();
    }

    if (simpleChanges.showTeamSelection && this.showAsDropdown && this.showTeamSelection && this.filteredTeamList && this.filteredTeamList.length === 1) {
      this.selectTeam(this.filteredTeamList[0]);
      this.regTouch();
    }

    this.addInvalidClass = false;
    if (this.formIsSubmitted && (!this.selectedId || this.selectedId < 0)) {
      this.addInvalidClass = true;
    }

    if (!simpleChanges.teamsList) { return; }

    if (this.teamsList && this.teamsList.length > 1) {
      this.teamsList.sort((a, b) => {
        if (a.teamName && b.teamName) {
          let textA = a.teamName.toUpperCase();
          let textB = b.teamName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }

        return 1;
      });
    }
    
    /**
     * COMMENT: Commented below code on Jan 03, 2024 as per the ticket 3318
     * Ticket description: Team Mode quests - always hide the default team in registration and donations unless it is the ONLY team
     * Meaning: we will display the tickets. no extra team will be displayed
     *
    // check if general fund exists for MQ
    // Map & Filter the Genral donation and the Default team
    this.teamsList.forEach(q => {
      if (!this.generalFundDetail || this.generalFundDetail == null || (this.generalFundDetail && !this.generalFundDetail.teamName)) {
        if (this.isMegaQuest) {
          if ((q.teamName.toLowerCase().indexOf('general fund') > -1 || q.teamName.toLowerCase().indexOf('general donation') > -1)) {
            this.generalFundDetail = q;  
          }
        } else {
          if (q.defaultTeam) {
            this.generalFundDetail = q;  
          }
        }
      }
    });

    let tempTeam = {
      teamId: -1,
      teamName: 'No Team',
      creatorId: null,
      questId: null,
      teamPageUrl: null,
      memberIds: [],
      teamLogoUrl: null
    };
    // id 1130 is used for prod check
    // removal of default team for JumpStart Quest id 1187
    if (!this.isForDonation && (
      (!environment.production && this.teamsList[0]['questId'] !== 2273 && this.teamsList[0]['questId'] !== 2450) || 
      (environment.production && this.teamsList[0]['questId'] !== 1130 && this.teamsList[0]['questId'] !== 1187 && this.teamsList[0]['questId'] !== 1220)
      )) {
        console.log('114')
      if (this.generalFundDetail && this.generalFundDetail.teamId && this.generalFundDetail.teamId !== null) {
        tempTeam.teamId = this.generalFundDetail.teamId;
      }

      this.filteredTeamList.push(tempTeam);
      console.log('120', this.filteredTeamList)
    } else if (this.isForDonation) {
      tempTeam.teamName = 'General Donation';
      if (this.generalFundDetail && this.generalFundDetail.teamId && this.generalFundDetail.teamId !== null) {
        tempTeam.teamId = this.generalFundDetail.teamId;
      }
      this.filteredTeamList.unshift(tempTeam);
    }

    if (this.isMegaQuest) {
      const _teams = this.teamsList.filter(q => q.teamName.toLowerCase().indexOf('general fund') < 0 && q.teamName.toLowerCase().indexOf('general donation') < 0);
      this.filteredTeamList.push(..._teams);
    } else {
      if (!(this.teamsList.length === 1 && this.generalFundDetail && this.generalFundDetail.teamName && this.generalFundDetail.teamName !== null)) {
        const _teams =  this.teamsList.filter(q => 
          q.teamName.toLowerCase().indexOf('general fund') < 0 && q.teamName.toLowerCase().indexOf('general donation') < 0 && !q.defaultTeam
        );
        this.filteredTeamList.push(..._teams);
        console.log('138', this.filteredTeamList)
      }
    }
    */

    if (this.teamsList && this.teamsList.length > 0) this.filteredTeamList = [...this.teamsList];
    // remove the default team if teams are more than 1
    // if (this.filteredTeamList.length > 1) {
    //   this.filteredTeamList = this.filteredTeamList.filter(tm => !tm.defaultTeam);
    // }

    if ((!this.showAsDropdown || (this.showTeamSelection && this.showAsDropdown)) && this.filteredTeamList && this.filteredTeamList.length === 1) {
      this.selectTeam(this.filteredTeamList[0]);
      this.regTouch();
    }
  }
  regTouch() {
    this.onTouched();

    this.teamSelect.emit(false);
    this.closedDropdown();
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  writeValue(value: string) {
    if (value && value !== undefined && value !== '') {
      this.selectedId = parseInt(value, 10);

      setTimeout(() => {
        this.eventsSubject.next();
      });
    }
  }
  selectTeam(teamdata) {
    let value = teamdata.teamId;

    this.onTouched();
    this.selectedId = value == '-1' ? null : parseInt(value, 10);
    this.propagateChange(this.selectedId);
    if (this.emitChanges) {
      this.teamSelect.emit(this.selectedId);
    }
    
    this.closedDropdown();
  }

  /*buildEmptyTeamOption() {
    // tslint:disable-next-line: max-line-length
    let emptyTeamOption = `
    <div class="c-dl-team-control__view is-empty">
      <img src="/assets/images/svg/SVG/freinds-group.svg">
    </div>
    <span class="c-dl-team-control__text no-team">No Team</span>
    `;

    if (this.isForDonation && !this.isMegaQuest && this.generalFundDetail && this.generalFundDetail.teamId && this.generalFundDetail.teamId !== null) {
      emptyTeamOption = `
        <div class="c-dl-team-control__view is-empty">
          <img src="${this.generalFundDetail.teamLogoUrl ? this.generalFundDetail.teamLogoUrl : '/assets/images/svg/SVG/freinds-group.svg'}">
        </div>
        <span class="c-dl-team-control__text no-team">${this.generalFundDetail.teamName || 'General Donation'}</span>
      `;
    } else if (this.isForDonation && this.isMegaQuest) {
      emptyTeamOption = `
      <div class="c-dl-team-control__view is-empty">
        <img src="${this.generalFundDetail && this.generalFundDetail.teamLogoUrl ? this.generalFundDetail.teamLogoUrl : '/assets/images/svg/SVG/freinds-group.svg'}">
      </div>
      <span class="c-dl-team-control__text no-team">${'General Donation'}</span>
    `;
    } else if (!this.isForDonation) {
      emptyTeamOption = `
      <div class="c-dl-team-control__view is-empty">
        <img src="${this.generalFundDetail && this.generalFundDetail.teamLogoUrl ? this.generalFundDetail.teamLogoUrl : '/assets/images/svg/SVG/freinds-group.svg'}">
      </div>
      <span class="c-dl-team-control__text no-team">${this.generalFundDetail.teamName || 'No Team'}</span>
    `;
    }

    return emptyTeamOption;
  }
  */

  openedDropdown() {
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select-opened");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '99';
      };
    }, 300);
  }

  closedDropdown() { 
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '9';
      };
    }, 500);
  }
}
