import { Directive, ElementRef, Input, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ProfileAvatarService } from 'src/app/components/separate/profile-avatar/profile-avatar.service';
import { Router } from '@angular/router';
import { WebviewNavigationDataType } from 'src/app/components/pages/quest-detail-comments/quest-detail-comments.component';
import tippy, { hideAll } from 'tippy.js';
import { CommentMention } from 'src/app/_interface/comment.types';

declare var $: any;

@Directive({
  selector: '[appMention]'
})
export class MentionDirective implements AfterViewInit, OnDestroy {
  @Output() navigateToEmit: EventEmitter<WebviewNavigationDataType> = new EventEmitter<WebviewNavigationDataType>();
  @Input('appMention') appMention: CommentMention[];
  @Input('webviewMode') webviewMode: boolean;
  // @Input('commentIndex') commentIndex: number;
  instance: any[] = [];

  constructor(
    private profileAvatarService: ProfileAvatarService,
    private el: ElementRef,
    private router: Router
    // private friendsService: FriendsService,
    // private store: Store<fromApp.AppState>,
  ) {
  }

  ngAfterViewInit() {
    const mentionsEl = this.el.nativeElement.querySelectorAll('.js-mention');
    mentionsEl.forEach(mantion => {
      const index = Number(mantion.getAttribute('data-index'));
      const instanceItem: any = tippy(mantion, {
        content: this.buildContent(this.appMention[index]),
        interactive: true,
        theme: 'light-border',
        onShow: event => {
          hideAll({ exclude: instanceItem });
          // const btnEl = $(event.popperChildren.content.querySelector('.tippy__tools'));
          const captoinEl = $(event.popper.querySelector('.tippy__caption'));
          // btnEl.removeClass('is-pending, is-accepted, is-blocked, is-active');
          // const statusClass = this.getStatusClass(this.appMention[index].friendStatus);
          // btnEl.addClass(statusClass);
          captoinEl.on('click.tippy', () => {
            if (this.webviewMode) {
              this.navigateToEmit.emit({
                type: 'LinkInside',
                routerLink: ['/profile', this.appMention[index].id]
              });
            } else {
              this.router.navigate(['/profile', this.appMention[index].id]);
            }
            instanceItem.hide(0);
          });
          // if (this.appMention[index].friendStatus === null) {
          //   btnEl.on('click.tippy', () => {
          //     instance.hide();
          //     this.sendFriendRequest(this.appMention[index].id, index);
          //   });
          // }
        },
        onHide: event => {
          // const btnEl = $(event.popperChildren.content.querySelector('.tippy__tools'));
          const captoinEl = $(event.popper.querySelector('.tippy__caption'));
          // btnEl.off('click.tippy');
          captoinEl.off('click.tippy');
        }
      });
      this.instance.push(instanceItem);

    });
  }
  ngOnDestroy() {
    if (this.instance.length > 0) {
      this.instance.forEach(instance => {
        instance.destroy();
      });
      this.instance = [];
    }
  }
  // getStatusClass(status: null | 0 | 1 | 2 | 3): string {
  //   switch (status) {
  //     case 0:
  //       return 'is-pending';
  //     case 1:
  //       return 'is-accepted';
  //     case 2:
  //       return 'is-blocked';
  //     case 3:
  //       return 'is-blocked';
  //     default:
  //       return 'is-active';
  //   }
  // }

  buildContent(info: CommentMention): string {
    let avatar;
    if (info.avatarUrl) {
      // tslint:disable-next-line:max-line-length
      avatar = `<div class="c-avatar__inner" style="background-image: ${info.avatarUrl ? ('url(' + info.avatarUrl + ')') : 'none'};"></div>`;
    } else {
      const firstLetter = info.firstName.substr(0, 1);
      const secondLetter = info.lastName.length > 1 ? info.lastName.substr(0, 1) : '';
      // tslint:disable-next-line:max-line-length
      avatar = `<div class="c-avatar__inner" style="background-color: ${this.profileAvatarService.getColor()};"><span>${firstLetter + secondLetter}</span></div>`;
    }
    return `<div class="tippy__mention" style="padding: 2px">
      <button class="tippy__caption h-btn-reset" type="button">
        <div class="tippy__avatar c-avatar">${avatar}</div>
        <div class="tippy__ttl" style="color: #2e3638">${info.firstName} ${info.lastName}</div>
      </button>
    </div>`;
  }
  // <div class="tippy__tools">
  //   <button class="tippy__friend-status h-btn-reset" type="button">
  //     <svg class="icon icon-plus"><use xlink:href="#icon-plus"></use></svg>
  //     <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
  //     <svg class="icon icon-plane"><use xlink:href="#icon-plane"></use></svg>
  //     <span class="active">Add to friends</span>
  //     <span class="pending">Pending</span>
  //     <span class="accepted">Accepted</span>
  //   </button>
  // </div>

  // sendFriendRequest(id: number, mentionIndex: number) {
  //   this.friendsService.sendFriendRequest(id).subscribe((res) => {
  //     const payload: CommentsActions.UpdateCommentMentionFriendStatusState = {
  //       commentIndex: this.commentIndex,
  //       mentionIndex: mentionIndex
  //     };
  //     this.store.dispatch(new CommentsActions.UpdateCommentMentionStatus(payload));
  //     this.notifier.success(
  //       'Friend request sent successfully.',
  //       null, this.snotifyConfigService.getConfig()
  //     );
  //   }, () => {
  //     this.notifier.error(
  //       'There was an error attempting to get suggested friends. Please try again.',
  //       null, this.snotifyConfigService.getConfig()
  //     );
  //   });
  // }
}
