import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { QuestComment } from 'src/app/_interface/comment.types';
import * as fromQuest from 'src/app/_interface/store/quest-detail.reducers';
import { AnimateItems, AnimateItemsUp, AnimateList } from 'src/app/animations/comments.animations';
import { WebviewNavigationDataType } from 'src/app/components/pages/quest-detail-comments/quest-detail-comments.component';
import { OpenLogActivityModal, QuestLoggedActivities } from '../../../../_interface/store/quest-detail.actions';
import { QuestService } from '../../../../_services/quest.service';
import * as fromApp from '../../../../_store/app.reducers';
import * as fromProfile from '../../../pages/profile/store/profile.reducer';
import * as fromAuth from '../../auth/store/auth.reducer';
import { DlLogInComponent } from '../../dl-auth/dl-log-in/dl-log-in.component';
import { PreparedQuestComment } from './comment/comment.type';
import * as CommentsActions from './store/quest-components.actions';
import * as fromQuestComponents from './store/quest-components.reducer';

export interface Mention {
  key: string;
  profilePictureURL: string;
  value: string;
  userId: number;
  brandName: string;
}
export interface MentionListItem {
  index: number;
  score: number;
  string: string;
  original: Mention;
}

@Component({
  selector: 'app-quest-comments',
  templateUrl: './quest-comments.component.html',
  styleUrls: ['./quest-comments.component.scss'],
  animations: [AnimateList, AnimateItems, AnimateItemsUp],
})
export class QuestCommentsComponent implements OnInit, OnDestroy {
  @Output() navigateToEmit: EventEmitter<WebviewNavigationDataType> = new EventEmitter<WebviewNavigationDataType>();

  questUserId: number;
  
  questId: number;
  
  @Input('postId') postId: number;

  loggedUserTeamId: number;
  
  userInfoState: Observable<fromProfile.State>;
  commentsState: Observable<fromQuestComponents.State>;
  
  authState: Observable<fromAuth.State>;

  commentsStateSubscription: Subscription;
  viewerId: number;
  isLoaded = false;
  comments: QuestComment[] = [];
  closeReplyForm: boolean = null;
  limit = 10;
  limitStep = 10;

  activityApiPage = 1;
  activityApiSize = 25;
  loggedActivities = [];
  loadMoreActivityApi = true;

  @Input() showLogActivityBtn: boolean;

  questState: Observable<fromQuest.QuestDetailState>;

  isCurrentUserAdminOrOwner: boolean = false;

  focusPostId: number;

  focusCommentId: number;
  
  focusReplyId: number;

  loadingMoreActivities: boolean = false;
  
  constructor(
    private questService: QuestService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {
    // this.comments = [];
    this.userInfoState = this.store.select('userInfo');
    this.commentsState = this.store.select('questComponents');
    this.questState = this.store.select('quest');
    this.authState = this.store.select('auth');
  }

  loadMore() {
    this.limit += this.limitStep;
  }
  ngOnInit() {
    this.focusPostId = this.route.snapshot.queryParams['postId'];
    this.focusCommentId = this.route.snapshot.queryParams['commentId'];
    this.focusReplyId = this.route.snapshot.queryParams['replyId'];

    this.questState.pipe(distinctUntilChanged()).subscribe(questState => {
      this.questUserId = questState.selectedQuestUserId;
      this.questId = questState.selectedQuestId;

      this.isCurrentUserAdminOrOwner = questState.isCurrentUserAdminOrOwner;
      
      this.loggedUserTeamId = questState.loggedUserTeamId;
    });

    this.questState.pipe(take(1)).subscribe(questState => {
      if (questState.selectedQuestDetail && questState.selectedQuestDetail.quest.isTemplate) {
        
        this.questService.getStaticQuestFeed(questState.selectedQuestDetail.quest.categoryCode).subscribe(res => {
          this.loggedActivities = [...res];

          this.store.dispatch(new QuestLoggedActivities(this.loggedActivities));
          this.isLoaded = true;
        })

      } else {
        this.commentsStateSubscription = this.commentsState.subscribe((state: fromQuestComponents.State) => {
          this.comments = state.comments;
          if (this.postId && this.comments.length > 0) {
            let index;
            this.comments.forEach((comment, i) => {
              comment.openReply = true
              if (comment.id == this.postId){
                index = i;
              }
              else if(comment.replies.some(reply => reply.id == this.postId || reply.replies.some(reply => reply.id == this.postId))){
                index = i;
                comment.openReply = true
              }
            })
            let pageNum = Math.floor(index / 10);
            while (pageNum > 0) {
              pageNum -= 1;
              this.loadMore();
            }
            this.waitComments();
          }
        });

        this.userInfoState.pipe(take(1)).subscribe((state: fromProfile.State) => {
          this.viewerId = state.id;

          this.loadActivities(true);

          /**
           * getting the legacy comments 
           */
          this.questService.getQuestComments(this.questId, this.questUserId, state.id).subscribe((res: PreparedQuestComment[]) => {
            this.store.dispatch(new CommentsActions.SetCommentsList(res));
            // this.isLoaded = true;
          });
        });
      }
    });
  }

  loadActivities(firstTimeLoad: boolean = false) {
    if (this.loadMoreActivityApi && !this.loadingMoreActivities) {
      this.loadingMoreActivities = true;
      
      this.questService.getLoggedActivities(this.questId, this.activityApiPage, this.activityApiSize).subscribe((res: Array<any>) => {
        if (!res || res.length === 0) {
          this.loadMoreActivityApi = false;
        }

        this.loggedActivities = [...this.loggedActivities, ...res];
        
        this.store.dispatch(new QuestLoggedActivities(this.loggedActivities));
        this.isLoaded = true;
        this.loadingMoreActivities = false;

        // check if focussed post is loaded
        if (firstTimeLoad && this.focusPostId && this.focusPostId > 0) {
          this.focusPostId = Number(this.focusPostId);
          let focussedPostExists = false;
          this.loggedActivities.forEach(act => {
            !focussedPostExists ? (focussedPostExists = act.actvityRecordValueId === this.focusPostId) : null;
          });

          if (focussedPostExists && !this.focusReplyId && !this.focusCommentId && document.getElementById(`quest-post-${this.focusPostId}`)) {
            // scroll to the post
            setTimeout(() => {
              window.scrollTo(0, document.getElementById(`quest-post-${this.focusPostId}`).offsetTop - 100);
              // document.getElementById(`quest-post-${this.focusPostId}`).scrollIntoView({behavior:"smooth", block:"start"});
              document.getElementById(`quest-post-${this.focusPostId}`).classList.add('highlight-focus-box');
              setTimeout(() => {
              document.getElementById(`quest-post-${this.focusPostId}`).classList.remove('highlight-focus-box');
              }, 2000);
            }, 100);
          } else {
            this.loadMoreActivity(true);
          }
        }
      }, err => {
        this.loadingMoreActivities = false;
      });
    }
  }

  async waitComments() {
    while(!document.getElementById(`${this.postId}`)) {
      await new Promise(r => setTimeout(r, 500));
    }
    document.getElementById(`${this.postId}`).scrollIntoView({behavior:"smooth", block:"center"});
  }

  ngOnDestroy() {
    if (this.commentsStateSubscription) {
      this.commentsStateSubscription.unsubscribe();
    }
    this.loggedActivities = [];
    this.store.dispatch(new CommentsActions.ClearCommentsList());
  }

  onNavigate(navigateData: WebviewNavigationDataType) {
    this.navigateToEmit.emit(navigateData);
  }

  onCloseAnotherReplyForms() {
    this.closeReplyForm = false;
    setTimeout(() => {
      this.closeReplyForm = null;
    }, 0);
  }

  loadMoreActivity(firstTimeLoad: boolean = false): void {
    this.questState.pipe(take(1)).subscribe(questState => {
      if (!questState.selectedQuestDetail.quest.isTemplate) {
        this.activityApiPage += 1;
        this.loadActivities(firstTimeLoad);
      }
    });
  }

  openLogActivityModal(): void {
    this.store.dispatch(new OpenLogActivityModal(true));
  }

  updateComments(event: {type: string; of?: number}) {
    if (event.type === 'removeFeed') {
      const index = this.loggedActivities.findIndex(item => item.actvityRecordValueId === event.of);
      this.loggedActivities.splice(index, 1);
    }
  }
}
