import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { QuestService } from '../../../_services/quest.service';

import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { QuestLogic } from 'src/app/_interface/quest.logic';
import { MemberActivity, QuestUserInfo } from 'src/app/_interface/quest.types';
import { AppState } from 'src/app/_store/app.reducers';
import { environment } from '../../../../environments/environment';
import { QUILL_CONFIG } from '../../../app.config';
import * as Constants from '../../../app.constants';
import * as fromProfile from '../../pages/profile/store/profile.reducer';
import { MembersService } from './members.service';


@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit, OnDestroy {

  userId: number;

  questId: number;

  @Input() allowEditQuest: boolean;
  
  showBacking: boolean;
  
  @Input() isCurrentUserQuestDoer: boolean = false;

  members: MemberActivity[] = [];

  isTemplate: boolean = false;

  userInfoState: Observable<fromProfile.State>;
  userInfoStateSubscription: Subscription;
  
  viewListDoers: MemberActivity[] = [];
  
  viewListDoersCutoff: MemberActivity[] = [];

  fullDoers: MemberActivity[] = [];

  doersCount: number;

  currentUser: QuestUserInfo;

  quillConfig = {...QUILL_CONFIG};
  messages = Constants.VALIDATION_MESSAGES;
  
  messageLimit = 4000;
  
  dialogInProgress = false;
  canSendBulkMessage = false;

  isLoading = false;
  messageAllForm: UntypedFormGroup;
  messageAllFormSubmitted = false;
  
  @ViewChild('doerContainer', { read: ElementRef, static: false }) doerContainer: ElementRef;

  maxCountToDisplay = 8;
  
  sendToOptions: Array<string> = [];

  @ViewChild('messageAllModal', { read: TemplateRef }) messageAllModal: TemplateRef<any>;

  modalReference: any = null;

  isMegaQuest: boolean;

  @Output() emitToMoveToPeopleTab: EventEmitter<boolean> = new EventEmitter<false>();

  draftMode: boolean = false;

  memberCount: number = 0;

  draftMemberArray: Array<string> = ['Aaron', 'Betty', 'Cameron', 'John'];

  membersCutOffTempArr = [1, 2, 3, 4];

  constructor(
    private questService: QuestService,
    private membersService: MembersService,
    private store: Store<AppState>,
    private notifier: NotifierService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef
  ) {
    this.userInfoState = this.store.select('userInfo');

    this.sendToOptions.push('All');

    this.messageAllForm = new UntypedFormGroup({
      'sendTo':  new UntypedFormControl('All'),
      'subject': new UntypedFormControl(null, []),
      'messageToAll': new UntypedFormControl('', [
        Validators.required,
        Validators.max(this.messageLimit)
      ]),
      'brandedMessage': new UntypedFormControl({value: true, disabled: false}, [])
    });
  }

  ngOnInit() {
    this.store.select('quest')
      .pipe(distinctUntilChanged())
      .subscribe(questState => {
        this.draftMode = questState.selectedQuestDetail && questState.selectedQuestDetail.quest.isDraft;

        this.questId = questState.selectedQuestId;
        this.userId = questState.selectedQuestUserId;

        this.showBacking = questState.selectedQuestDetail && questState.selectedQuestDetail.quest.showBackingAmount;

        this.isMegaQuest = questState.selectedQuestDetail && questState.selectedQuestDetail.isMegaQuest;

        this.isTemplate = questState.selectedQuestDetail && questState.selectedQuestDetail.quest.isTemplate;

        this.memberCount = questState.selectedQuestMembersAndCount && questState.selectedQuestMembersAndCount.totalMemberCount;

        if (questState.selectedQuestMembersAndCount && this.members.length !== questState.selectedQuestMembersAndCount.members.length) {
          this.members = questState.selectedQuestMembersAndCount && questState.selectedQuestMembersAndCount.members;
          
          this.getMembers();
        }
      });

    this.getGroupsForMsg();

    this.userInfoStateSubscription = this.userInfoState.subscribe((currentUser: fromProfile.State) => {
      this.currentUser = currentUser;

      this.canSendBulkMessage = this.allowEditQuest && 
                                  currentUser.accountTier && 
                                  currentUser.accountTier.level && 
                                  (currentUser.accountTier.level === 'Pro' || currentUser.accountTier.level === 'Ent');
    });

  }

  ngOnDestroy() {
    this.activeModal.close();
    this.destroySubscriptions();
  }

  private destroySubscriptions() {
    if (this.userInfoStateSubscription) {
      this.userInfoStateSubscription.unsubscribe();
    }
  }

  private prepareListDoers(doers: MemberActivity[]): void {
    this.maxCountToDisplay = 8;

    if (this.doerContainer && this.doerContainer.nativeElement.offsetWidth && Number(this.doerContainer.nativeElement.offsetWidth)) {
      this.maxCountToDisplay = (Number(this.doerContainer.nativeElement.offsetWidth) / (60 + 17)) < 7 ? 8 : (Number(this.doerContainer.nativeElement.offsetWidth) / (60 + 17));
    }

    this.maxCountToDisplay = Math.floor(this.maxCountToDisplay);

    this.viewListDoers = [...doers].filter((member: MemberActivity) => {
      if (member.memberStatus) {
        return member.memberStatus.length === 1 && member.memberStatus[0] === 'Interested' ? false : true;
      }
      return false;
    });
    this.doersCount = doers.length; //this.viewListDoers.filter(dr => !dr['acceptRequest']).length;
    
    const end = this.viewListDoers.length > this.maxCountToDisplay ? this.maxCountToDisplay : this.viewListDoers.length;
    this.viewListDoersCutoff = this.viewListDoers.slice(0, end);
    this.membersCutOffTempArr = [1, 2, 3, 4].slice(0, 4-this.viewListDoersCutoff.length);
    
    this.cdr.detectChanges();
  }

  private prepareDoers(doers: MemberActivity[]): MemberActivity[] {
    doers.forEach((item: MemberActivity, index: number) => {
      item.memberStatus = item.memberStatus.sort((a, b) => {
        return Constants.MEMBER_STATUS_PRIORITY[a] - Constants.MEMBER_STATUS_PRIORITY[b];
      });
      /* TODO: Below is old code when MemberActivity was QuestDoer. But not Percentage not being used anywhere so commented.
      / doers[index].percentage = item.totalTasksCount ? item.completeTasksCount / item.totalTasksCount * 100 : 0;
      */
    });

    return doers;
  }

  openMembersDialog() {
    this.emitToMoveToPeopleTab.emit(true);
  }

  openMessageAllDialog() {
    this.isLoading = true;
    this.messageAllForm.patchValue({
      sendTo: 'All',
      subject: null,
      messageToAll: '',
      brandedMessage: true
    });
    setTimeout(() => {
      this.modalReference = this.modalService.open(this.messageAllModal, {
        windowClass: 'no-overflow'
      });
      this.isLoading = false;
    });
  }

  closeDialog() {
    this.activeModal.close();
    if(this.modalReference) this.modalReference.close();
    this.modalReference = null;
  }

  sendMessageForDoers() {
    this.messageAllFormSubmitted = true;

    if (this.dialogInProgress || this.messageAllForm.invalid) {
      return;
    }

    const message = this.messageAllForm.get('messageToAll').value.trim().replace(/<p><br><\/p>/g, '<br>').replace(/<p>/g, '').replace(/<\/p>/g, '<br>');
    if (message.length > this.messageLimit) {
      // this.notifier.notify('error', `Maximum message length is ${this.messageLimit}`);
      // commented above as already displaying msg of max length
      return;
    }

    this.dialogInProgress = true;
    const subject = (this.messageAllForm.get('subject').value || '').trim() || null;
    const plain = !this.messageAllForm.get('brandedMessage').value;
    const sendTo = this.messageAllForm.get('sendTo').value;

    this.membersService.sendMessageAll(this.questId, message, subject, sendTo, plain).subscribe(() => {
      this.dialogInProgress = false;
      this.notifier.notify('success', 'Your message has been sent.');
      
      this.closeDialog();
    }, () => {
      this.dialogInProgress = false;
      this.notifier.notify('error', 'There was an issue completing your request. Please try again.');
    });
  }

  private getMembers() {
    // not calling the api if the quest is start DIEMlife
    if ((!environment.production && this.questId === 115) || (environment.production && this.questId === 222)) return false;

    if (this.questId && this.userId) {
      if (this.currentUser && this.currentUser.id) {
        this.isCurrentUserQuestDoer = QuestLogic.checkIfCurrentUserQuestDoer(this.members, this.currentUser.id);
      }

      if (this.isTemplate) {
        this.viewListDoersCutoff = this.members;
        this.doersCount = this.members.length;
      }
    }

    if (!this.isTemplate) {
      this.fullDoers = this.prepareDoers([...this.members]);
      setTimeout(() => {
        this.prepareListDoers([...this.members]);
      });
    }
  }

  getGroupsForMsg() {
    if (this.questId) {
      this.questService.getGroupsForQuest(this.questId).subscribe((res) => {
        if (res && res['length'] > 0) {
          (res as Array<any>).forEach(element => {
            this.sendToOptions.push(element.group);          
          });
        }
      })
    }
  }

  showMessageLengthErr(): boolean {
    if (!this.messageAllForm.value.messageToAll || this.messageAllForm.value.messageToAll === '' || this.messageAllForm.value.messageToAll.trim() === '') {
      return false;
    }

    const message = this.messageAllForm.get('messageToAll').value.trim().replace(/<p><br><\/p>/g, '<br>').replace(/<p>/g, '').replace(/<\/p>/g, '<br>');
    return message.length > this.messageLimit;
  }
}
