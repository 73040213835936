import { Action } from '@ngrx/store';
import { RealtimeQuestForm } from 'src/app/_interface/quest.types';
import * as fromProfile from './profile.reducer';
import { AccountPlan } from 'src/app/_interface/account-tier.types';
import { User, UserPermission } from 'src/app/_interface/user-profile.types';

export const TRY_GET_USER_INFO = 'TRY_GET_USER_INFO';
export const GET_USER_INFO = 'GET_USER_INFO';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const REALTIME_QUEST_STARTED = 'REALTIME_QUEST_STARTED';
export const REALTIME_QUEST_FINISHED = 'REALTIME_QUEST_FINISHED';
export const FINISH_REALTIME_QUEST = 'FINISH_REALTIME_QUEST';
export const REALTIME_QUEST_FINISH_FAILED = 'REALTIME_QUEST_FINISH_FAILED';
export const REALTIME_QUEST_REPEATED = 'REALTIME_QUEST_REPEATED';
export const RESET_QUEST_SAVED_STATE = 'RESET_QUEST_SAVED_STATE';
export const UPDATE_USER_ACCOUNT_TIER_INFO = 'UPDATE_USER_ACCOUNT_TIER_INFO';
export const GET_USER_ACCOUNT_TIER_INFO = 'GET_USER_ACCOUNT_TIER_INFO';
export const GET_PLAN_TIER_DETAILS = 'GET_PLAN_TIER_DETAILS';
export const SET_PLAN_TIER_DETAILS = 'SET_PLAN_TIER_DETAILS';
export const GET_FRIENDS_CURRENT = 'GET_FRIENDS_CURRENT';
export const GET_FRIENDS_NO_ACTION = 'GET_FRIENDS_NO_ACTION';
export const GET_FRIENDS_REQUESTS = 'GET_FRIENDS_REQUESTS';
export const SET_FRIENDS_CURRENT = 'SET_FRIENDS_CURRENT';
export const SET_FRIENDS_REQUESTS = 'SET_FRIENDS_REQUESTS';
export const SET_FRIENDS_NO_ACTION = 'SET_FRIENDS_NO_ACTION';
export const GET_USER_MEMBERSHIP_MANAGER = 'GET_USER_MEMBERSHIP_MANAGER';
export const UPDATE_MEMBERSHIP_MANAGER_DETAILS = 'UPDATE_MEMBERSHIP_MANAGER_DETAILS';
export const GET_USER_PERMISSIONS = 'GET_USER_PERMISSIONS';
export const UPDATE_USER_PERMISSIONS = 'UPDATE_USER_PERMISSIONS';
export const UPDATE_ALLOW_CREATE_QUESTS = 'UPDATE_ALLOW_CREATE_QUESTS';
export const UPDATE_ALLOW_CREATE_EVENTS = 'UPDATE_ALLOW_CREATE_EVENTS';
export const UPDATE_ALLOW_CREATE_TEMPLATES = 'UPDATE_ALLOW_CREATE_TEMPLATES';
export const UPDATE_ALLOW_USE_TEMPLATES_PRO = 'UPDATE_ALLOW_USE_TEMPLATES_PRO';
export const UPDATE_ALLOW_USE_TEMPLATES_ENT = 'UPDATE_ALLOW_USE_TEMPLATES_ENT';

export class UpdateUserInfo implements Action {
  readonly type = UPDATE_USER_INFO;

  constructor(public payload: fromProfile.State) {}
}
export class TryGetUserInfo implements Action {
  readonly type = TRY_GET_USER_INFO;
}

export class GetUserMembershipManager implements Action {
  readonly type = GET_USER_MEMBERSHIP_MANAGER;

  constructor(public payload: number) {}
}

export class UpdateMembershipManagerDetails implements Action {
  readonly type = UPDATE_MEMBERSHIP_MANAGER_DETAILS;
  
  constructor(public isManager: boolean, public allPlans: AccountPlan[]) {
    console.log('UpdateMembershipManagerDetails::', isManager, allPlans);
  }
}

export class GetUserInfo implements Action {
  readonly type = GET_USER_INFO;

  constructor(public payload: fromProfile.State) {}
}

export class ClearUserInfo implements Action {
  readonly type = CLEAR_USER_INFO;
}

export class RealtimeQuestStarted implements Action {
  readonly type = REALTIME_QUEST_STARTED;

  constructor(public questId: number, public userId: number) {
    console.log('ProfileActions::RealtimeQuestStarted', questId, userId);
  }
}

export class RealtimeQuestFinished implements Action {
  readonly type = REALTIME_QUEST_FINISHED;

  constructor(public questId: number) {
    console.log('ProfileActions::RealtimeQuestFinished', questId);
  }
}
export class RealtimeQuestRepeated implements Action {
  readonly type = REALTIME_QUEST_REPEATED;

  constructor(public questId: number, public userId: number) {
    console.log('ProfileActions::RealtimeQuestRepeated', questId, userId);
  }
}

export class ResetQuestSavedState implements Action {
  readonly type = RESET_QUEST_SAVED_STATE;
}

export class FinishRealtimeQuest implements Action {
  readonly type = FINISH_REALTIME_QUEST;

  constructor(public payload: RealtimeQuestForm) {}
}

export class RealtimeQuestFinishFailed implements Action {
  readonly type = REALTIME_QUEST_FINISH_FAILED;

  constructor(public reason: string) {}
}


export class UpdateUserAccountTierInfo implements Action {
  readonly type = UPDATE_USER_ACCOUNT_TIER_INFO;

  constructor(public payload: AccountPlan) {
    console.log('AuthActions::UpdateUserAccountTierInfo');
  }
}

export class GetUserAccountTierInfo implements Action {
  readonly type = GET_USER_ACCOUNT_TIER_INFO;

  constructor(public payload: number) {
    console.log('AuthActions::GetUserAccountTierInfo');
  }
}

export class GetPlanTierDetails implements Action {
  readonly type = GET_PLAN_TIER_DETAILS;

  constructor(public payload: string) {}
}

export class GetFriendsCurrent implements Action {
  readonly type = GET_FRIENDS_CURRENT;

  constructor() { }
}

export class SetFriendsCurrent implements Action {
  readonly type = SET_FRIENDS_CURRENT;

  constructor(public payload: User[]) { }
}

export class GetFriendsRequests implements Action {
  readonly type = GET_FRIENDS_REQUESTS;

  constructor() {}
}

export class SetFriendsRequests implements Action {
  readonly type = SET_FRIENDS_REQUESTS;

  constructor(public payload: User[]) {}
}

export class GetFriendsNoAction implements Action {
  readonly type = GET_FRIENDS_NO_ACTION;

  constructor() {}
}

export class SetFriendsNoAction implements Action {
  readonly type = SET_FRIENDS_NO_ACTION;

  constructor(public payload: User[]) {}
}

export class GetUserPermissions implements Action {
  readonly type = GET_USER_PERMISSIONS;

  constructor(public payload: number) {}
}

export class UpdateUserPermissions implements Action {
  readonly type = UPDATE_USER_PERMISSIONS;

  constructor(public payload: UserPermission) {
    console.log('ProfileAction:UpdateUserPermissions', payload);
  }
}

export class UpdateAllowCreateQuests implements Action {
  readonly type = UPDATE_ALLOW_CREATE_QUESTS;

  constructor(public payload: boolean) {}
}

export class UpdateAllowCreateEvents implements Action {
  readonly type = UPDATE_ALLOW_CREATE_EVENTS;

  constructor(public payload: boolean) {}
}

export class UpdateAllowCreateTemplates implements Action {
  readonly type = UPDATE_ALLOW_CREATE_TEMPLATES;

  constructor(public payload: boolean) {}
}

export class UpdateAllowUseTemplatesPro implements Action {
  readonly type = UPDATE_ALLOW_USE_TEMPLATES_PRO;

  constructor(public payload: boolean) {}
}

export class UpdateAllowUseTemplatesEnt implements Action {
  readonly type = UPDATE_ALLOW_USE_TEMPLATES_ENT;

  constructor(public payload: boolean) {}
}

export type ProfileActions =  
  TryGetUserInfo |
  GetUserInfo |
  ClearUserInfo |
  UpdateUserInfo |
  RealtimeQuestStarted |
  RealtimeQuestFinished |
  FinishRealtimeQuest |
  RealtimeQuestFinishFailed |
  RealtimeQuestRepeated |
  ResetQuestSavedState |
  UpdateUserAccountTierInfo |
  GetUserAccountTierInfo |
  GetPlanTierDetails |
  GetFriendsCurrent |
  GetFriendsRequests |
  GetFriendsNoAction |
  SetFriendsCurrent |
  SetFriendsRequests |
  SetFriendsNoAction |
  GetUserMembershipManager |
  UpdateMembershipManagerDetails |
  GetUserPermissions |
  UpdateUserPermissions |
  UpdateAllowCreateQuests |
  UpdateAllowCreateEvents |
  UpdateAllowCreateTemplates |
  UpdateAllowUseTemplatesPro | 
  UpdateAllowUseTemplatesEnt;
