import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import * as htmlToText from 'html-to-text';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { QuestLitePrepared } from 'src/app/_interface/quest.types';
import { QuestService } from 'src/app/_services/quest.service';
import { AppState } from 'src/app/_store/app.reducers';
import { ExploreCard } from '../../pages/explore/explore-card/explore-card.type';
import * as fromProfile from '../../pages/profile/store/profile.reducer';
import { QuestPreparedPhoto } from '../quest/quest.type';
import { ShareWidgetModalComponent } from '../share-widget-modal/share-widget-modal.component';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('initial', style({
        opacity: 0
      })),
      state('final', style({
        opacity: 1
      })),
      transition('initial=>final', animate('1500ms')),
      transition('final=>initial', animate('1000ms'))
    ]),
  ],
})
export class CardComponent implements OnChanges, OnInit {
  @Input() quest: QuestLitePrepared | ExploreCard;
  profileState: Observable<fromProfile.State>;
  photo: QuestPreparedPhoto;
  placeholderMode = false;
  currentState = 'initial';
  text = '';
  modalReference: any = null;
  questImageStyle: any = {};

  constructor(
    private questService: QuestService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private notifier: NotifierService,
    @Inject ('isServer') private isServer: boolean
  ) {
    this.profileState = this.store.select('userInfo');
  }

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    if (Object.keys(this.quest).length === 0) {
      this.placeholderMode = true;
      return false;
    }
    this.photo = this.questService.prepareCoverPhoto(this.quest.photo, this.quest.category);

    if (this.photo && this.photo.jpg) {
      this.questImageStyle = {
        'background': `url('${this.photo.jpg}') 0 0 / cover no-repeat`,
        width: '100%',
        height: '100%'
      };
      console.log('74', this.photo, this.questImageStyle)
    }

    this.getDescription();
    setTimeout(() => {
      this.currentState = 'final';
    }, 100);
  }

  getLinkToQuestDetail() {
    if (this.quest.seoSlugs) {
      // '/', this.quest.seoSlugs.userNameSlug || '', this.quest.seoSlugs.questTitleSlug || '', this.quest.id, this.quest.user.id
      return [this.quest.seoSlugs.shortUrl];
    } else {
      return ['/quest-detail', this.quest.id, this.quest.user.id];
    }
  }

  private getDescription() {
    if (this.quest.questFeed ? this.quest.questFeed.length > 0 : false) {
      this.text = htmlToText.convert(this.quest.questFeed, {
        wordwrap: null,
        singleNewLineParagraphs: true,
        preserveNewlines: true
      });
    } else {
      this.text = this.quest.shortDescription;
    }
  }

  getAbbrNum(number, decPlaces): string {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);

    // Enumerate number abbreviations
    const abbrev = ['k', 'm', 'b', 't'];

    // Go through the array backwards, so we do the largest first
    for (let i = abbrev.length - 1; i >= 0; i--) {

      // Convert array index to "1000", "1000000", etc
      const size = Math.pow(10, (i + 1) * 3);

      // If the number is bigger or equal do the abbreviation
      if (size <= number) {
        // Here, we multiply by decPlaces, round, and then divide by decPlaces.
        // This gives us nice rounding to a particular decimal place.
        number = Math.round(number * decPlaces / size) / decPlaces;

        // Handle special case where we round up to the next abbreviation
        if ((number === 1000) && (i < abbrev.length - 1)) {
          number = 1;
          i++;
        }

        // Add the letter for the abbreviation
        number += abbrev[i];

        // We are done... stop
        break;
      }
    }

    return number;
  }

  getTimeAgo() {
    if (this.quest.dateCreated) {
      return moment(this.quest.dateCreated).fromNow(true).replace(' ', '<br>');
    } else {
      return '';
    }
  }

  getColor(): string { // get color association with category
    switch (this.quest.category) {
      case 'ENVIRONMENTAL':
        return '#295967';
      case 'PHYSICAL':
        return '#168cb2';
      case 'MENTAL':
        return '#3caac5';
      case 'SOCIAL':
        return '#437b8a';
      case 'FINANCIAL':
        return '#6d787e';
      case 'OCCUPATIONAL':
        return '#2e3638';
      default:
        return '#295967';
    }
  }

  openModal() {
    if (!this.isServer) {
      setTimeout(() => {
        this.modalReference = this.modalService.open(ShareWidgetModalComponent, {
          windowClass: 'no-overflow'
        });
        this.modalReference.componentInstance.questId = this.quest.id;
      });
    }
  }

  saveQuest() {
    this.questService.saveQuest(this.quest.id).subscribe((res: any) => {
      if (res.error) {
        this.notifier.notify('error', res.error.message);
      }
      if (res.success) {
        this.profileState.pipe(take(1)).subscribe((fromProfileState: fromProfile.State) => {
          this.notifier.notify('success', `Quest saved`);
        });
      }
    });
  }

  closeModal() {
    // this.openedModal = '';
    this.activeModal.close();
    if (this.modalReference) this.modalReference.close();
    this.modalReference = null;
  }

  showTierBadge(templateType: string, isTemplate: boolean) : boolean {
    if (isTemplate && templateType && templateType !== '' && templateType !== 'null') {
      const _templateType = templateType.toLowerCase();
      
      return _templateType !== 'free';
    }

    return false;
  }
}
