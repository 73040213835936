import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[appInputAutoHeight]'
})
export class InputAutoHeightDirective implements AfterViewInit {
  @Input('appInputAutoHeight') appInputAutoHeight: FormControl;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
  }

  ngAfterViewInit() {
    this.setHeight(this.appInputAutoHeight.value);

    this.appInputAutoHeight.valueChanges.subscribe(res => {
      this.setHeight(res);
    });
  }

  setHeight(contentVal) {
    if (contentVal === '' || contentVal.trim() === '') {
      this.renderer.removeStyle(this.el.nativeElement, 'height');
      
      return;
    }

    this.renderer.setStyle(this.el.nativeElement, 'height', 'auto');
    this.renderer.setStyle(this.el.nativeElement, 'height', this.el.nativeElement.scrollHeight + 'px');
  }
}
