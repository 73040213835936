import { Component, OnInit, Input } from '@angular/core';
import { QuestUserInfo } from 'src/app/_interface/quest.types';

@Component({
  selector: 'app-quest-info',
  templateUrl: './quest-info.component.html',
  styleUrls: ['./quest-info.component.scss']
})
export class QuestInfoComponent implements OnInit {
  @Input() questUserInfo: QuestUserInfo;
  constructor() { }

  ngOnInit() {
    console.log(this.questUserInfo);
  }

}
