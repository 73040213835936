import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../../../_services/common.service';
import { NgxGridComponent } from '@egjs/ngx-grid';
import { MAXIMUM_VIDEO_SIZE, VIDEO_EXTENSION_ALLOWED } from 'src/app/app.config';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit, OnChanges {
  @Input('acceptCount') acceptCount: string = 'single';

  @Input('acceptFor') acceptFor: string;
  
  @Input('title') title: string;
  
  @Input('userEmail') userEmail: string = '';

  @Input('existingImages') existingImages: (string | {imageURL: string, videoURL: string})[] = []; //must be array of url

  @Input('showSubmitButtons') showSubmitButtons: boolean = true;

  @Input('uploadImage') uploadImage: boolean = false;

  @Input('showImageInfo') showImageInfo: boolean = true;

  @Input('smallWindowAvailable') smallWindowAvailable: boolean = false;

  files: any[] = [];
  
  imgCustomWidth: number;
  
  imgCustomHeight: number;

  imageUploading: boolean = false;

  disableSubmitBtn: boolean = false;

  @Output('imageUploadedData') imageUploadedData: EventEmitter<any> = new EventEmitter();

  @Output('imageSelectedData') imageSelectedData: EventEmitter<any> = new EventEmitter();

  contentType: any;

  imageDataSaving: boolean = false;

  allFileData: any[] = [];

  fileUploadProgress: number = 0;

  videoFilesUrl: string[] = [];

  @ViewChild("gridElem") gridElem: NgxGridComponent;

  uploadedImageUrls: (string | {imageURL: string, videoURL: string})[] = [];

  fileAlreadyUploadedSize: number = 0;

  @Output('assetUploading') assetUploading: EventEmitter<boolean> = new EventEmitter(false);

  assetsToBeRemoved: any[] = [];

  @Input('removeUploadedImages') removeUploadedImages: boolean = false;

  newlyUploadedFiles: {imageURL: string, videoURL: string}[] = [];

  showAssetSizeError: boolean = false;

  MAXIMUM_VIDEO_SIZE: number = MAXIMUM_VIDEO_SIZE;

  @Input('isTemplate') isTemplate: boolean = false;

  @Input('acceptVideo') acceptVideo: boolean = false;

  @Input('triggerClick') triggerClick: boolean = false;

  @Input('hideUploadSection') hideUploadSection: boolean = false;

  @ViewChild('fileDropRef', {read: ElementRef, static: false}) fileDropRef: ElementRef;

  constructor(private commonService: CommonService,
    private modalService: NgbModal,
    public sanitizer: DomSanitizer,
    private activeModal: NgbActiveModal,
    private notifier: NotifierService) { }

  ngOnInit() {
    if (this.existingImages && this.existingImages.length > 0) {
      this.uploadedImageUrls = [...this.existingImages];

      this.existingImages.forEach(f => {
        // console.log('71', f)
        setTimeout(() => {
          if (typeof f === 'object' && f.videoURL && f.videoURL !== '' && f.videoURL !== 'null') {
            // console.log('74', f);
            this.imageCallback(f.videoURL, 0, true, false, true, f, f.videoURL);
          } else if (typeof f === 'object') {
            // console.log('76', f);
            this.imageCallback(f.imageURL, 0, false, false, true, f, f.imageURL);
          }
          else {
            // console.log('82', f);
            const fileObj = {
              imageURL: f,
              videoURL: null
            };
            this.imageCallback(f, 0, false, false, true, fileObj, f);
          }
        }, 50);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.uploadImage) {
      this.submitAssets();
    }

    if (this.removeUploadedImages) {
      if (this.newlyUploadedFiles.length > 0) {
        this.newlyUploadedFiles.forEach(removeAsset => {
          this.removeAssetS3({
            assetUrl: removeAsset
          });
        });

        this.modalService.dismissAll();
        this.activeModal.dismiss();
        this.activeModal.close();
      }
    }

    if (changes.triggerClick && this.triggerClick) {
      this.fileDropRef.nativeElement.click();

      this.triggerClick = false;
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  removeImage(index: number, event: any) {
    this.showAssetSizeError = false;
    
    if (this.gridElem) {
      this.assetsToBeRemoved.push(this.allFileData[index]);
      if (!this.existingImages || this.existingImages.length === 0) {
        this.removeAssetS3(this.allFileData[index]);
      }

      this.allFileData.splice(index, 1);
      // this.existingImages.splice(index, 1);
      this.uploadedImageUrls.splice(index, 1);

      this.imageSelectedData.emit(this.allFileData.length > 0 ? true : false);
      this.gridElem.updateItems();
    }
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(allFiles: Array<any>) {
    this.files = [];
    this.showAssetSizeError = false;
    const fileLength = allFiles.length;
    
    [...allFiles].forEach((item, i) => {
      item.progress = 0;

      const _fileName = item.name.toLowerCase().split('.');
      if (item.size <= MAXIMUM_VIDEO_SIZE) {
        this.files.push(item);

        setTimeout(() => {
          const nameSplit = item.name.toLowerCase().split('.');
          if (VIDEO_EXTENSION_ALLOWED.indexOf(nameSplit[nameSplit.length - 1]) > -1) { 
            this.videoFilesUrl.push(URL.createObjectURL(item));
            this.imageCallback(item, item.size, true, (fileLength - 1) === i, false, null, this.videoFilesUrl[this.videoFilesUrl.length - 1]);
          } else {
            this.commonService.readImageAndReturnBase64(item, this.imageCallback.bind(this, item, item.size, false, (fileLength - 1) === i, false, null));
            this.videoFilesUrl.push('');
          }
        }, 200 * i);
      } else {
        this.showAssetSizeError = true;
      }
    });
  }

  async imageCallback(actualFile, fileSize, isVideo, callUpload = false, assetUploaded, assetUrlData = null, fileData) {
    const length = this.allFileData.length;
    this.allFileData.push({
      data: isVideo ? this.sanitizer.bypassSecurityTrustUrl(fileData) : fileData,
      size: fileSize,
      tillPreviousSize: length < 1 ? fileSize : (this.allFileData[length - 1].tillPreviousSize + fileSize),
      isVideo: isVideo,
      uploading: false,
      uploaded: this.isTemplate ? true : assetUploaded,
      actualFileData: actualFile,
      assetUrl: this.isTemplate ? {
        imageURL: isVideo ? null : fileData,
        videoURL: isVideo ? fileData : null
        } : assetUrlData,
      screenshotData: isVideo ? await this.getScreenshotFromVideo(fileData, this.allFileData.length) : null,
      screenshotDataAdded: false
    });

    if (this.gridElem) {
      this.gridElem.updateItems();
    }

    // upload assets
    if (callUpload) {
      this.imageSelectedData.emit(true);

      this.assetUploading.emit(true);

      // !this.isTemplate ? this.uploadAllAssets() : null;
      setTimeout(() => {
        !this.isTemplate ? this.prepareForUpload() : null;        
      }, 500);
    }
  }

  prepareForUpload() {
    let startUpload = false;
    const videoAsset = this.allFileData.filter(f => f.isVideo);
    // console.log('236', videoAsset, startUpload);
    if (videoAsset.length > 0) {
      const screenshotNotAdded = videoAsset.filter(f => !f.screenshotDataAdded);
      // console.log('239', screenshotNotAdded, startUpload);
      if (screenshotNotAdded.length > 0) {
        startUpload = false;
      } else {
        startUpload = true;
      }
    } else {
      startUpload = true;
    }

    // console.log('249', startUpload)
    if (startUpload) {
      this.uploadAllAssets()
    } else {
      setTimeout(() => {
        this.prepareForUpload();
      }, 500);
    }
  }

  renderComplete(ev) {
  }

  getScreenshotFromVideo(fileData, index) {
      this.getVideoDimensionsOf(fileData).then((dimRes: {imageBase64: string}) => {
        this.allFileData[index]['screenshotData'] = this.dataURLtoFile(dimRes.imageBase64, (this.allFileData[index].actualFileData.name || '_temp_screenshot_video') + '.jpeg');
        this.allFileData[index]['screenshotDataAdded'] = true;
      })
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }


  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
 
  closeModal() {
    this.modalService.dismissAll();
  }

  uploadAllAssets() {
    this.imageUploading = true;
    this.uploadImage = false;
    let newFilesTobeUploaded = [...this.allFileData];
    let indexUploading = [];

    if (this.acceptCount === 'single') {
      // if (this.existingImages && this.existingImages.length > 0) {
      //   this.uploadedImageUrls = [...this.existingImages];
      //   this.imageUploading = false;
      //   this.assetUploading.emit(false);
      
      //   return;
      // }

      indexUploading.push(0);
      this.allFileData[0].uploading = true;
    }

    if (this.acceptCount === 'multiple') {
      // if (this.existingImages && this.existingImages.length > 0) {
        // nothing changed
        // if (this.existingImages.length === this.allFileData.length) {
        //   this.uploadedImageUrls = [...this.existingImages];
        //   this.imageUploading = false;
        //   this.assetUploading.emit(false);

        //   return;
        // }

        // image deleted
        let allFilesAlreadyUploaded = true;
        newFilesTobeUploaded = [];
        this.allFileData.forEach((fileData, ind) => {
          if (!fileData.uploaded) {
            allFilesAlreadyUploaded = false;
            newFilesTobeUploaded.push(fileData);
            indexUploading.push(ind);
            this.allFileData[ind].uploading = true;
          }
        });

        if (allFilesAlreadyUploaded) {
          this.uploadedImageUrls = [...this.existingImages];
          this.imageUploading = false;
          this.assetUploading.emit(false);

          return;
        }
      // }

      // newFilesTobeUploaded = [];
      // this.allFileData.forEach((fileData, ind) => {
      //   if (!fileData.uploaded) {
      //     indexUploading.push(ind);
      //     newFilesTobeUploaded.push(fileData);
      //   }
      // });
    }

    // upload image and get url
    let formData = new FormData();
    newFilesTobeUploaded.forEach((f, i) => {
      formData.append("file", f.actualFileData);
      if (f.isVideo )  {
        formData.append("file", f.screenshotData);
      }
    });

    // indexUploading.forEach(val => {
    //   this.allFileData[val].uploading = true;
    // });
    
    if (this.acceptCount === 'single') {
      this.commonService
        .uploadImageAndGetUrl(formData)
        .subscribe(imageResp => {
          if (imageResp.type == HttpEventType.UploadProgress) {
            this.fileUploadProgress = imageResp.loaded;
          }

          if (imageResp.type === HttpEventType.Response) {
            this.imageUploading = false;
            this.fileAlreadyUploadedSize += this.fileUploadProgress;

            this.uploadedImageUrls = [imageResp.body];
            this.newlyUploadedFiles = [imageResp.body];

            indexUploading.forEach(ind => {
              this.allFileData[ind].uploading = false;
              this.allFileData[ind].uploaded = true;

              // map urls to object
              this.allFileData[ind].assetUrl = imageResp.body
            });
       
            this.assetUploading.emit(false);

            // this.existingImages = [...this.uploadedImageUrls];

            setTimeout(() => {
              if (this.gridElem) {
                this.gridElem.updateItems();
              }
            }, 1000);
          }
        });
    } else {
      this.commonService
        .uploadMultipleImagesAndGetUrl(formData)
        .subscribe(imageResp => {
          if (imageResp.type == HttpEventType.UploadProgress) {
            this.fileUploadProgress = imageResp.loaded;
          }

          if (imageResp.type === HttpEventType.Response) {
            this.fileAlreadyUploadedSize += this.fileUploadProgress;

            this.imageUploading = false;
            this.uploadedImageUrls = [...this.uploadedImageUrls, ...imageResp.body]
            this.newlyUploadedFiles = [...this.newlyUploadedFiles, ...imageResp.body];
            
            indexUploading.forEach(ind => {
              this.allFileData[ind].uploading = false;
              this.allFileData[ind].uploaded = true;

              this.allFileData[ind].assetUrl = this.uploadedImageUrls[ind];
            });
    
            this.assetUploading.emit(false);

            // this.existingImages = [...this.uploadedImageUrls];

            setTimeout(() => {
              if (this.gridElem) {
                this.gridElem.updateItems();
              }
            }, 1000);
          }
        });
    }
  }

  submitAssets() {
    if (this.isTemplate) {
      this.imageUploadedData.emit({
        assetData: null
      });

      return;
    }

    if (this.assetsToBeRemoved.length > 0) {
      this.assetsToBeRemoved.forEach(removeAsset => {
        this.removeAssetS3(removeAsset);
      })
    }
    
    this.saveImage({
      imageURL: this.uploadedImageUrls
    });
  }

  saveImage(imageData) {
    this.imageDataSaving = true;

    switch (this.acceptFor) {
      case 'questGallery':
      case 'logActivity':
        this.imageUploadedData.emit({
          assetData: imageData.imageURL
        });
        break;

      case 'taskImage':
      case 'profileLink':
        console.log('480', imageData)
        this.imageUploadedData.emit({
          imageUrl: imageData.imageURL[0]
        });

        break;

      case 'teamLogo':
      case 'questCoverImage':
      case 'teamCover':
      case 'fundraiseImage':
        this.imageUploadedData.emit({
          imageUrl: imageData.imageURL[0] && imageData.imageURL[0].imageURL ? imageData.imageURL[0].imageURL : imageData.imageURL[0]
        });

        break;
    }
  }

  clearAllImages() {
    this.disableSubmitBtn = false;
    this.allFileData = [];
    this.existingImages = [];
  }

  getFileUploadProgress(index): number {
    const totalSizePrev = this.allFileData[index].tillPreviousSize;
    const totalUploaded = this.fileAlreadyUploadedSize + this.fileUploadProgress;
    let value = parseFloat(((totalUploaded / totalSizePrev) * 100).toFixed(2));
    
    if (index > 0 && totalUploaded < this.allFileData[index - 1].tillPreviousSize) {
      value = 0;
    }

    return value > 100 ? 100 : value;
  }

  removeAssetS3(fileData): void {
    this.commonService
      .removeAssetFromS3(fileData.assetUrl.imageURL)
      .subscribe(res => {
        if (!res.Success) {
          console.log('Unable to delete the asset');
        }
      });
    
    if (fileData.assetUrl.videoURL && fileData.assetUrl.videoURL !== '' && fileData.assetUrl.videoURL !== 'null') {
      this.commonService
        .removeAssetFromS3(fileData.assetUrl.videoURL)
        .subscribe(res => {
          if (!res.Success) {
            console.log('Unable to delete the asset');
          }
        });
    }

  }

  /**
 Returns the dimensions of a video asynchrounsly.
 @param {String} url Url of the video to get dimensions from.
 @return {Promise<{width: number, height: number}>} Promise which returns the dimensions of the video in 'width' and 'height' properties.
 */
  getVideoDimensionsOf(url){
    return new Promise(resolve => {
        const video = document.createElement('video');
        video.style.visibility = 'hidden';
        video.preload = 'metadata';
        video.autoplay = true;
        video.currentTime = 1;
        video.load();

        // place a listener on it
        video.addEventListener( "loadeddata", function () {
            // retrieve dimensions
            const height = this.videoHeight;
            const width = this.videoWidth;

            let canvas: any = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            setTimeout(() => {
              canvas.getContext('2d').drawImage(video, 0, 0, width, height);
              resolve({imageBase64: canvas.toDataURL('image/jpeg')});  
            }, 200);
        }, false);

        // start download meta-datas
        video.muted = true;
        video.src = url;
        video.play();
    });
  }

}
