import { CurrencyPipe, DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { GetQuestGalleryAssets } from 'src/app/_interface/store/quest-detail.actions';
import { QuestService } from 'src/app/_services/quest.service';
import { AppState } from 'src/app/_store/app.reducers';
import { AnimateItems } from 'src/app/animations/comments.animations';
import { COMMENT_CHARACTER_LIMIT, QUILL_CONFIG } from 'src/app/app.config';
import { SHARE_DATE_FORMAT } from 'src/app/app.constants';
import { LogActivityComponent } from 'src/app/components/separate/log-activity/log-activity.component';
import { QuestComment } from '../../../../../_interface/comment.types';
import { LoggedActivity } from '../../../../../_interface/quest.types';
import { GalleryFullscreenComponent } from '../../../../../modules/shared-module/components/gallery-fullscreen/gallery-fullscreen.component';
import * as fromProfile from '../../../../pages/profile/store/profile.reducer';
import * as fromAuth from '../../../auth/store/auth.reducer';
import { CommentService } from '../comment/comment.service';
import { LogActivityService } from 'src/app/_services/log-activity.service';
import { ShareWidgetModalComponent } from '../../../share-widget-modal/share-widget-modal.component';

@Component({
  selector: 'app-quest-comment-item',
  templateUrl: './quest-comment-item.component.html',
  styleUrls: ['./quest-comment-item.component.scss'],
  animations: [AnimateItems],
  providers: [CurrencyPipe]
})
export class QuestCommentItemComponent implements OnInit, OnChanges {
  @Input() loggedItem: LoggedActivity;

  @Input() viewerId: number;

  @Input() questId: number;

  @Input() userId: number;
  
  @Input() loggedUserTeamId: number;

  sharedDate: string;

  hasCurrentUserLiked: boolean = false;

  authState: Observable<fromAuth.State>;

  isLoading: string = null;

  profileState: Observable<fromProfile.State>;

  isUserAuthenticated: boolean = false;

  replyForm: UntypedFormGroup;

  tributeOptions: any;

  showRepliesList: boolean = false;

  showOptions: boolean = false;

  @Output() update: EventEmitter<{type: string; of?: number}> = new EventEmitter<{type: string; of?: number}>(); 

  modalReference: NgbModalRef = null;

  @ViewChild('feedOptionsBox', { read: ElementRef }) feedOptionsBox: ElementRef;

  public commentLength = COMMENT_CHARACTER_LIMIT;

  loggedInUserInfo: any;

  userComments: QuestComment[];

  @Input('isCurrentUserAdminOrOwner') isCurrentUserAdminOrOwner: boolean = false;

  ngxConfig = {...QUILL_CONFIG};

  isTemplate: boolean = false;

  mentionsList: Array<any> = [];

  tributeVal: string = '';

  commentPageNumber: number = 1;

  commentPageSize: number = 10;

  focusCommentId: number;

  focusPostId: number;

  focusReplyId: number;

  attributeValueDisplay: string = '';

  activityAttributesValue: Array<{label: string; value: string | number;}> = [];

  @HostListener('document:click', ['$event'])
  outsideClick(event): void {
    if (this.feedOptionsBox && !this.feedOptionsBox.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private questService: QuestService,
    private notifier: NotifierService,
    private fb: UntypedFormBuilder,
    private commentService: CommentService,
    private modalService: NgbModal,
    private currencyPipe: CurrencyPipe,
    private logActivityService: LogActivityService) { 
    this.authState = this.store.select('auth');
  }

  ngOnInit() {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.focusCommentId = this.route.snapshot.queryParams['commentId'];
      this.focusPostId = this.route.snapshot.queryParams['postId'];
      this.focusReplyId = this.route.snapshot.queryParams['replyId'];
    });

    this.authState.subscribe(res => {
      this.isUserAuthenticated = res.authenticated;
    });
    this.profileState = this.store.select('userInfo');

    this.replyForm = this.fb.group({
      editor: ['', [Validators.required, Validators.maxLength(this.commentLength)]]
    });
   
    this.tributeOptions = this.commentService.getTributeOptions();

    this.profileState.subscribe(res => {
      this.loggedInUserInfo = res;
    });

    this.store.select('quest').subscribe(questState => {
      this.isTemplate = questState.selectedQuestDetail && questState.selectedQuestDetail.quest.isTemplate;
    });

    this.replyForm.controls.editor.valueChanges.subscribe(res => {
      let syntaxMessage = res;
      let mentionText = this.commentService.escape(syntaxMessage);

      this.mentionsList.forEach(mention => {
        const formattedMention = {
          ...mention,
          key: this.commentService.escape(mention.key)
        };
        let textSyntax = `@${formattedMention.key}`;
        let textHighlight = `<a href="javascript:void(0);"><span>@${formattedMention.key}</span></a>`;
        mentionText = mentionText.replace(new RegExp(textSyntax.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1"), 'g'), textHighlight);        
      });

      mentionText = mentionText.replace(/\n/g, '<br />'); //Replace the escape character for <br />
      mentionText = mentionText.replace(/ {2}/g, '&nbsp; '); //Replace the 2 preceding token to &nbsp;

      this.tributeVal = mentionText;
    });

    if (this.focusCommentId && this.focusCommentId > 0 && this.focusPostId && Number(this.focusPostId) === this.loggedItem.actvityRecordValueId) {
      this.focusComment(true);
    }
  }

  ngOnChanges(): void {
    this.userComments = this.loggedItem.userComments.reverse();

    this.sharedDate = moment.utc(this.loggedItem.creationDateTime).local().format(SHARE_DATE_FORMAT);
    if (this.viewerId) {
      this.hasCurrentUserLiked = this.loggedItem.likes.count > 0 ? (this.loggedItem.likes.users.filter(like => like.userId === this.viewerId).length > 0) : false;
    }

    // if (this.loggedItem && 
    //   this.loggedItem.attributeName && 
    //   (this.loggedItem.attributeName.toLowerCase() === 'duration' 
    //     || this.loggedItem.attributeName.toLowerCase() === 'time')) {
    //   let attVal: any = this.loggedItem.attributeValue;
      
    //   if (attVal.indexOf(':') > -1) {
    //     attVal = attVal.split(':');
        
    //     attVal.length === 3 ? (this.loggedItem.attributeValue = (attVal[0] > 0 ? (attVal[0] + 'h ') : '')  + (attVal[1] > 0 ? (attVal[1] + 'm ') : '') + (attVal[2] > 0 ? (attVal[2] + 's') : '')) 
    //       : 
    //       (attVal.length === 2 ? (this.loggedItem.attributeValue = (attVal[0] > 0 ? (attVal[0] + 'h ') : '')  + (attVal[1] > 0 ? (attVal[1] + 'm ') : '')) : null);
    //   }
    // }

    if (this.loggedItem &&
      (this.loggedItem.unitNameSingular === 'ounce' || this.loggedItem.unitNamePlural === 'ounces')) {
      this.loggedItem.attributeName = 'Volume';
    }

    // this.prepareAttributeValue();

    // add the attributes 
    this.activityAttributesValue = [];
    if (this.loggedItem.completedValue) {
      this.activityAttributesValue.push({
        label: 'Completed',
        value: null
      })
    } 
    
    if (this.loggedItem.countValue) {
      this.activityAttributesValue.push({
        label: 'Count',
        value: this.loggedItem.countValue
      })
    }
    
    if (this.loggedItem.distanceValue) {
      this.activityAttributesValue.push({
        label: 'Distance',
        value: this.logActivityService.convertGenericToNormal('distance', this.loggedItem.distanceValue)
      })
    }

    if (this.loggedItem.elevationValue) {
      this.activityAttributesValue.push({
        label: 'Elevation',
        value: this.logActivityService.convertGenericToNormal('elevation', this.loggedItem.elevationValue)
      })
    }
    
    if (this.loggedItem.durationValue) {
      this.activityAttributesValue.push({
        label: 'Duration',
        value: this.logActivityService.convertGenericToNormal('duration', this.loggedItem.durationValue)
      })
    }
    
    if (this.loggedItem.repsValue) {
      this.activityAttributesValue.push({
        label: 'Reps',
        value: this.loggedItem.repsValue
      })
    }
    
    if (this.loggedItem.volumeValue) {
      this.activityAttributesValue.push({
        label: 'Volume',
        value: this.logActivityService.convertGenericToNormal('volume', this.loggedItem.volumeValue)
      })
    }
    
    if (this.loggedItem.amountValue) {
      this.activityAttributesValue.push({
        label: 'Amount',
        value: this.logActivityService.convertGenericToNormal('amount', this.loggedItem.amountValue)
      })
    }

    switch(this.loggedItem.pillarName) {
      case 'physical':
        this.loggedItem.activityClass = 'physical';
        return;
      case 'occupational':
        this.loggedItem.activityClass = 'occupational';
        return;
      case 'mental':
        this.loggedItem.activityClass = 'mental';
        return;
      case 'friends':
        this.loggedItem.activityClass = 'friends';
        return;
      case 'piggybank':
        this.loggedItem.activityClass = 'piggybank';
        return;
      default:
        this.loggedItem.activityClass = 'environmental';
        return;
    }
  }

  prepareCommentCRLF(comment: string) {
    return comment && comment.replace(/\n/gm, '<br>');
  }

  toggleLikeComment() {
    if (!this.isUserAuthenticated) return;

    this.store.select('quest').pipe(take(1)).subscribe(questState => {
      if (!questState.selectedQuestDetail.quest.isTemplate) {
        this.isLoading = 'LIKE';
        this.questService.toggleQuestLike(this.questId, this.loggedItem.actvityRecordValueId).subscribe((likedData: any) => {
          this.loggedItem.likes = likedData;
          this.hasCurrentUserLiked = this.loggedItem.likes.count > 0 ? (this.loggedItem.likes.users.filter(like => like.userId === this.viewerId).length > 0) : false;
          this.isLoading = null;
        }, () => {
          this.notifier.notify('error', `Something went wrong.`);
          this.isLoading = null;
        });
      }
    });
  }

  toggleReplyComment(focus: boolean) {
    this.showReplies();
  }

  showReplies() {
    this.showRepliesList = true;
  }

  openGallery(focusIndex) {
    const _mod = this.modalService.open(GalleryFullscreenComponent, {
      backdropClass: 'carousel-bg',
      windowClass: 'carousel-modal'
    });

    const assetToSend = [];
    this.loggedItem.images.forEach(asset => {
      assetToSend.push({
        questImageUrl: asset.imageURL,
        questVideoUrl: asset.videoURL
      });
    });
    _mod.componentInstance.assets = assetToSend; //this.loggedItem.images;
    _mod.componentInstance.focusedIndex = focusIndex;
  }

  replyToComment() {
    if (!this.isLoading) {
      this.store.select('quest').pipe(take(1)).subscribe(questState => {
        if (questState.selectedQuestDetail.quest.isTemplate) {
          this.replyForm.get('editor').patchValue('');
          this.notifier.notify('success', 'Commented successfully.');
        }
        else {
          this.isLoading = 'REPLY';

          let mentionStr = '';
          this.mentionsList.forEach((mention, i) => {
            mentionStr += (i == 0) ? mention.userId : (',' + mention.userId);
          });

          this.questService
            .addNewQuestComment(this.questId, this.loggedItem.actvityRecordValueId, this.replyForm.value.editor, null, mentionStr)
            .subscribe((replyData: QuestComment) => {
              this.isLoading = null;
              this.replyForm.get('editor').patchValue('');

              this.userComments.push(replyData);
            }, () => {
              this.isLoading = null;
            });
        }
      });
      
    }
  }

  loadMoreComments(firstTimeLoaded: boolean = false) {
    this.store.select('quest').pipe(take(1)).subscribe(questState => {
      if (!questState.selectedQuestDetail.quest.isTemplate) {
        this.questService
          .getCommentsOnPost(this.loggedItem.actvityRecordValueId, this.commentPageNumber, this.commentPageSize)
          .subscribe((comments: any) => {
            const existingComs = this.userComments.map(com => com.commentId);
            comments = comments.filter(com => existingComs.indexOf(com.commentId) < 0);
            comments = comments.reverse();
    
            this.commentPageNumber += 1;
    
            this.userComments.unshift(...comments);

            if (firstTimeLoaded) this.focusComment(true);
          })
      }
    });
  }

  cancelReply() {
    this.replyForm.patchValue({
      editor: ''
    });
  }

  showAbbreviation(): boolean {
    if (!this.loggedItem || (this.loggedItem && !this.loggedItem.attributeName)) {
      return;
    }

    return this.loggedItem.attributeName 
      && this.loggedItem.attributeName.toLowerCase() !== 'count' 
      && this.loggedItem.attributeName.toLowerCase() !== 'reps'
      && this.loggedItem.attributeName.toLowerCase() !== 'duration'
      && this.loggedItem.attributeName.toLowerCase() !== 'completed'
      && this.loggedItem.attributeName.toLowerCase() !== 'amount';
  }

  showAttributeName(): boolean {
    if (!this.loggedItem || (this.loggedItem && !this.loggedItem.attributeName)) {
      return;
    }

    return this.loggedItem.attributeName && this.loggedItem.attributeName.toLowerCase() !== 'completed';
  }

  // showAttributeValue(): boolean {
  //   return this.loggedItem.attributeName && this.loggedItem.attributeName.toLowerCase() !== 'completed';
  // }

  editFeed(): void {
    this.store.select('quest').pipe(take(1)).subscribe(questState => {
      if (!questState.selectedQuestDetail.quest.isTemplate) {
        this.showOptions = false;
        this.modalReference = this.modalService.open(LogActivityComponent, {
          size: 'lg',
          windowClass: 'log-activity-modal no-overflow',
          backdrop: 'static'
        });
          
        this.modalReference.result.then(res => {
          this.modalReference = null;
        });
      
        this.modalReference.componentInstance.questId = this.questId;
        this.modalReference.componentInstance.userId = this.userId;
        this.modalReference.componentInstance.teamId = this.loggedUserTeamId;

        this.modalReference.componentInstance.isEditActivity = true;
        this.modalReference.componentInstance.editActivityData = this.loggedItem;
      }
    })
  }

  confirmDeleteFeed(template) {
    this.store.select('quest').pipe(take(1)).subscribe(questState => {
      if (!questState.selectedQuestDetail.quest.isTemplate) {
        this.modalReference = this.modalService.open(template, {
          size: 'sm',
          windowClass: 'confirm-modal'
        });
      }
    });
  }

  closeModal() {
    this.modalReference.dismiss();
    this.modalService.dismissAll();
  }

  deleteFeed() {
    this.showOptions = false;
    this.questService
      .deleteFeed(this.loggedItem['actvityRecordValueId'])
      .pipe(finalize(() => this.closeModal()))
      .subscribe(res => {
        this.update.emit({
          type: 'removeFeed',
          of: this.loggedItem['actvityRecordValueId']
        });

        this.store.dispatch(new GetQuestGalleryAssets(this.questId, this.userId));
        this.notifier.notify('success', 'Activity deleted successfully.');
      }, err => {
        this.notifier.notify('error', 'Activity deletetion failed.');
      })
  }

  updatedComment(event) {
    if (event.type === 'removeComment') {
      const index = this.userComments.findIndex(item => item.commentId === event.of);
      this.userComments.splice(index, 1);
      this.loggedItem.totalComments -= 1;
    }
  }

  openCheerModal(modalTemplate) {
    if (this.loggedItem.likes.count > 0) {
      this.modalReference = this.modalService.open(modalTemplate, {
        size: 'sm',
        windowClass: 'cheer-modal'
      });
    }
  }
  
  returnStyle(image) {
    return {
      'background': `url('${image}') 50% 50% / cover no-repeat`
    };
  }

  isAssetVideo(asset) {
    return asset && asset.videoURL && asset.videoURL !== '' && asset.videoURL !== 'null';
  }

  returnCheersText() {
    let cheerText = '';

    // check if loggedin user exists, then move that to 0th index
    let loggedInUserIndex = this.loggedItem.likes.users.findIndex(user => user.userId === this.loggedInUserInfo.id);

    if (loggedInUserIndex > -1) {
      // remove from original list and move atop
      let currentUserInfo = this.loggedItem.likes.users.filter(user => user.userId === this.loggedInUserInfo.id)
      this.loggedItem.likes.users.splice(loggedInUserIndex, 1);
      this.loggedItem.likes.users.unshift(...currentUserInfo);
    }

    this.loggedItem.likes.users.forEach((user, uInd) => {
      let userName = user.brand && user.brand.name ? user.brand.name : (user.userFirstName + ' ' + user.userLastName);
      
      if (uInd === 0) {
        cheerText += user.userId === this.loggedInUserInfo.id ? 'You' : userName;
        if (this.loggedItem.likes.count > 2) {
          cheerText += ', '
        }
      }
      else if (uInd === 1) {
        if (this.loggedItem.likes.count === 2) {
          cheerText += ' and ';
        }
        cheerText += user.userId === this.loggedInUserInfo.id ? 'You' : userName;
      }
      else if (uInd === 2 && this.loggedItem.likes.count > 2) {
        cheerText += (' and ' + (this.loggedItem.likes.count - 2) + ' ' + ((this.loggedItem.likes.count - 2) > 1 ? 'others' : 'other'));
        return;
      }
    });

    return cheerText;
  }

  returnCheerTooltip() {
    let cheerTooltip = '';

    this.loggedItem.likes.users.forEach((user, uInd) => {
      let userName = user.brand && user.brand.name ? user.brand.name : (user.userFirstName + ' ' + user.userLastName);
      
      if (uInd < 15) {
        cheerTooltip += '<div>' + (user.userId === this.loggedInUserInfo.id ? 'You' : userName) + '</div>';
      }
      else if (uInd === 15) {
        cheerTooltip += '<div>' + (this.loggedItem.likes.count - 15) + ' ' + ((this.loggedItem.likes.count - 15) > 1 ? 'others' : 'other') + '</div>';
      }
      else {
        return;
      }
    });

    return cheerTooltip;
  }

  mentionItemSelected(ev) {
    if (this.mentionsList.length === 0 || this.mentionsList.findIndex(ment => ment.value === ev.value) < 0) {
      this.mentionsList.push(ev);
    }
  }

  commentKeyUp(ev) {
    if (ev.keyCode === 8 || ev.keyCode === 46) {
      const msg = this.replyForm.value.editor;
      if (msg.indexOf('@') < 0) {
        this.mentionsList = [];

        return;
      }

      if (this.mentionsList.length === 0) return;

      let allMentions = [...this.mentionsList];
      allMentions.forEach((mention, i) => {
        let regexr = new RegExp(`@${mention.key}\\W`)
        if (!msg.match(regexr) || (msg.match(regexr) && msg.match(regexr).length < 1)) {
          allMentions.splice(i, 1);
        }
      });
      this.mentionsList = [...allMentions];
    }
  }

  focusComment(firstTimeLoaded: boolean = false) {
    if (firstTimeLoaded && this.focusCommentId && this.focusCommentId > 0) {
      this.focusCommentId = Number(this.focusCommentId);
      let focusCommentExists = false;
      this.userComments.forEach(act => {
        !focusCommentExists ? (focusCommentExists = act.commentId === this.focusCommentId) : null;
      });

      if (focusCommentExists && !this.focusReplyId) {
        // scroll to the post
        setTimeout(() => {
          window.scrollTo(0, document.getElementById(`quest-post-comment-${this.focusCommentId}`).offsetTop - 100);
          // document.getElementById(`quest-post-comment-${this.focusCommentId}`).scrollIntoView({behavior:"smooth", block:"start"});
          document.getElementById(`quest-post-comment-${this.focusCommentId}`).classList.add('highlight-focus-box');
          setTimeout(() => {
            document.getElementById(`quest-post-comment-${this.focusCommentId}`).classList.remove('highlight-focus-box');
          }, 2000);
        }, 100);
      } else {
        if (this.loggedItem?.totalComments > this.loggedItem.userComments?.length) this.loadMoreComments(true);
      }
    }
  }

  // prepareAttributeValue() {
  //   if (this.showAttributeValue()) {
  //     this.attributeValueDisplay = (this.loggedItem.attributeName === 'Amount' ? this.currencyPipe.transform(this.loggedItem.attributeValue / 100, 'USD') : this.loggedItem.attributeValue) + '';
  //     return;
  //   } 
    
  //   this.attributeValueDisplay = '';
  // }

  openShareModal() {
    this.modalReference = this.modalService.open(ShareWidgetModalComponent, {
      windowClass: 'no-overflow'
    });
    this.modalReference.componentInstance.questId = this.questId;
    this.modalReference.componentInstance.feedPostId = this.loggedItem.actvityRecordValueId;
  }
}