import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-skip-nav',
  templateUrl: './skip-nav.component.html',
  styleUrls: ['./skip-nav.component.scss']
})
export class SkipNavComponent implements OnInit {
  @Input() target: string;
  doc: any;

  constructor() { }

  ngOnInit() {

  }
  scroll(event) {
    event.preventDefault();
    const el = document.getElementById(this.target);

    el.scrollIntoView();
    window.scrollBy(0, -40);
  }

}
