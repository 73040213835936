import { Action } from '@ngrx/store';

export const SHOW_SEARCH = 'SHOW_SEARCH';
export const HIDE_SEARCH = 'HIDE_SEARCH';
export const RESET_SEARCH = 'RESET_SEARCH';

export class ShowSearch implements Action {
  readonly type = SHOW_SEARCH;
}
export class HideSearch implements Action {
  readonly type = HIDE_SEARCH;
}
export class ResetSearch implements Action {
  readonly type = RESET_SEARCH;
}

export type SearchActions = ShowSearch |
                            HideSearch |
                            ResetSearch;
