import * as SearchActions from './search.actions';

export interface State {
  active: boolean;
}

export const initialState: State = {
  active: null
};

export function searchReducer (state = initialState, action: SearchActions.SearchActions) {
  switch (action.type) {
    case (SearchActions.SHOW_SEARCH):
      return {
        ...state,
        active: true
      };
    case (SearchActions.HIDE_SEARCH):
      return {
        ...state,
        active: false
      };
    case (SearchActions.RESET_SEARCH):
      return {
        ...initialState
      };
    default:
      return state;
  }
}
