import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged, switchMap, take, takeUntil} from 'rxjs/operators';
import {forkJoin, Observable, Subject, Subscription} from 'rxjs';
import {SearchService} from 'src/app/_services/search.service';
import {Store} from '@ngrx/store';
import * as fromApp from './../../../../_store/app.reducers';
import * as fromProfile from '../../../../components/pages/profile/store/profile.reducer';
import {MyQuestsSevice} from 'src/app/components/pages/my-quests/my-quests.service';
import {QuestService} from 'src/app/_services/quest.service';
import {QuestPreparedPhoto} from 'src/app/components/main/quest/quest.type';
import { Filter, MyFriendsInfo, SearchResponse, SearchResult } from 'src/app/_interface/search.types';
import { ExploreCardListType } from 'src/app/_interface/explore-page.types';

export interface SelectedSearchResult {
  data: SearchResult;
  preparedUrl: any[];
}

@Component({
  selector: 'app-dl-search',
  templateUrl: './dl-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DlSearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchField') searchElement: ElementRef;
  @Output() resultSelected: EventEmitter<SelectedSearchResult> = new EventEmitter<SelectedSearchResult>();
  @Output() opened: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() isOpened = false;
  @Input() focusOnInit = false;
  @Input() searchFor: 'Quests' | 'People' | 'All';
  usersQuests: { [key: number]: ExploreCardListType } = {};
  results: SearchResult[] = [];
  searchForm: UntypedFormGroup;
  searchText = '';
  isLoading = false;
  isLoadingMore = false;
  loadmoreEnabled = false;
  limit = 15;
  startIndex = 0;
  filter: Filter = {
    isActive: {
      title: 'All',
      prop: 'global'
    },
    items: [
      {
        title: 'All',
        prop: 'global'
      },
      {
        title: 'Users',
        prop: 'people'
      },
      {
        title: 'Quests',
        prop: 'quests'
      }
    ]
  };
  newContentLoaded = false;
  myFriendsInfo: MyFriendsInfo = null;
  userId: number;

  private destroyed$: Subject<void> = new Subject();
  private cleared$: Subject<void> = new Subject();

  private searchChangesSubscription: Subscription;
  private searchResultsSubscription: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private searchService: SearchService,
    private store: Store<fromApp.AppState>,
    private myQuestsService: MyQuestsSevice,
    private questService: QuestService
  ) {
  }

  ngOnInit(): void {
    if (typeof this.searchFor === 'string') {
      let prop: string;

      switch (this.searchFor) {
        case 'All':
          prop = 'global';
          break;
        case 'People':
          prop = 'people';
          break;
        case 'Quests':
          prop = 'quests';
          break;
        default:
          break;
      }
      this.filter.isActive = {
        title: this.searchFor,
        prop: prop
      };
    }

    this.store.select('userInfo')
    .pipe(take(1))
    .subscribe((state: fromProfile.State) => {
      this.userId = state.id;
    });

    this.searchForm = this.fb.group({
      search: ['', [Validators.required]]
    });

    this.getFriendsMapping();
    this.getUserQuests();

    if (this.focusOnInit) {
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    }
  }

  onFocus(): void {
    if (!this.searchChangesSubscription || this.searchChangesSubscription.closed) {
      this.searchChangesSubscription = this.subscribeForSearchChanges();
    }
    if (!this.searchResultsSubscription || this.searchResultsSubscription.closed) {
      this.searchResultsSubscription = this.subscribeForSearchResultChanges();
    }
    if (!this.searchText) {
      this.isLoading = true;
      this.isLoadingMore = false;
      this.cdr.detectChanges();

      this.loadMore(true, true, false);
    }

    this.open();
  }

  open(): void {
    this.isOpened = true;
    this.cdr.detectChanges();
    this.opened.emit(this.isOpened);
  }

  close(): void {
    this.isOpened = false;
    this.clear();
    this.opened.emit(this.isOpened);
  }

  private clear(): void {
    if (this.searchChangesSubscription) {
      this.searchChangesSubscription.unsubscribe();
    }
    if (this.searchResultsSubscription) {
      this.searchResultsSubscription.unsubscribe();
    }
    this.cleared$.next();
    this.cleared$.complete();
    this.searchForm.patchValue({
      search: ''
    }, {
      onlySelf: true,
      emitEvent: false
    });
    this.searchText = '';
    this.isLoading = false;
    this.isLoadingMore = false;
  }

  setUserStatuses(data: SearchResult[]): SearchResult[] {
    return data.map((user) => {
      Object.keys(this.myFriendsInfo).forEach((key) => {
        user = this.setUserStatusByKey(user, key);
      });
      return user;
    });
  }

  setUserStatusByKey(user: SearchResult, key: string): SearchResult {
    this.myFriendsInfo[key].forEach((userId) => {
      if (userId === user.userId) {
        user.status = key;
      }
    });
    return user;
  }

  prepareCoverPhoto(photo, category): QuestPreparedPhoto {
    return this.questService.prepareCoverPhoto(photo, category);
  }

  selectItem(index: number): void {
    const selected = this.results[index];
    let url = [];

    if (selected.type === 'quest') {
      // tslint:disable-next-line: max-line-length
      url = ['/quest-detail', selected.questId, (this.usersQuests.hasOwnProperty(selected.questId) ? this.usersQuests[selected.questId].seoSlugs.userId : selected.userId)];
    } else if (selected.type === 'user') {
      url = ['/profile', selected.userId];
    }

    this.close();

    this.resultSelected.emit({
      data: this.results[index],
      preparedUrl: url
    });

  }

  loadMore(resetResults: boolean, showCentralSpinner: boolean, showBottomSpinner: boolean): any {
    if (resetResults) {
      this.resetResults();
    }
    if (this.loadmoreEnabled) {
      if (showCentralSpinner) {
        this.isLoading = true;
      }
      if (showBottomSpinner) {
        this.isLoadingMore = true;
      }
      this.cdr.detectChanges();

      this.forceLoadMore().pipe(take(1)).subscribe((searchResponse: SearchResponse) => {
        this.updateResults(searchResponse);
      }, (err) => {
        console.log(err);
        this.isLoading = false;
        this.isLoadingMore = false;
        this.cdr.detectChanges();
      });
    }
  }

  ngOnDestroy(): void {
    this.clear();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private subscribeForSearchResultChanges(): Subscription {
    return this.searchForm.get('search').valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.cleared$),
      switchMap(val => {
        this.searchText = val;
        this.resetResults();
        this.isLoading = true;
        this.isLoadingMore = false;
        this.cdr.detectChanges();
        return this.forceLoadMore();
      })
    ).subscribe((searchResponse: SearchResponse) => {
      this.updateResults(searchResponse);
    }, (err) => {
      console.log(err);
      this.isLoading = false;
      this.isLoadingMore = false;
      this.cdr.detectChanges();
    });
  }

  private resetResults(): void {
    this.loadmoreEnabled = true;
    this.startIndex = 0;
    this.results = [];
    this.cdr.detectChanges();
  }

  private forceLoadMore(): Observable<SearchResponse> {
    return this.searchService.getSearchResults({
      q: this.searchText.toLocaleLowerCase(),
      _start: this.startIndex,
      _limit: this.limit,
      type: this.filter.isActive.prop
    });
  }

  private updateResults(searchResponse: SearchResponse): void {
    if (searchResponse.data && this.myFriendsInfo) {
      this.loadmoreEnabled = searchResponse.more;
      this.startIndex = searchResponse.start + searchResponse.limit;
      this.results = [...this.results, ...this.setUserStatuses(searchResponse.data)];
      setTimeout(() => {
        this.newContentLoaded = true;
        this.cdr.detectChanges();
      });
    }
    this.isLoadingMore = false;
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  private getFriendsMapping(): void {
    this.searchService.getFriendsMapping().subscribe((data: MyFriendsInfo) => {
      this.myFriendsInfo = data;
    }, (err) => {
      console.log(err);
      this.myFriendsInfo = {
        approved: [],
        sent: [],
        received: []
      };
    });
  }

  private getUserQuests(): void {
    forkJoin([
      this.myQuestsService.getAllActiveQuestsForUser(),
      this.myQuestsService.getQuestsCompletedForUser()
    ]).subscribe(([activeQuests, completedQuests]) => {
      [...activeQuests, ...completedQuests].forEach(quest => {
        if (!this.usersQuests.hasOwnProperty(quest.id)) {
          this.usersQuests[quest.id] = quest;
        }
      });
    });
  }

  private subscribeForSearchChanges(): Subscription {
    // * open search box if text in input is changed
    return this.searchForm.get('search').valueChanges
    .pipe(takeUntil(this.cleared$))
    .subscribe(() => {
      if (!this.isOpened) {
        this.open();
      }
    });
  }

}
