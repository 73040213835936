import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ReaquestHeadersService} from '../../../../_services/requestHeaders.service';
import {StaticEnvironmentService} from '../../../../_services/static-environment-service';
import { DlMilestonesService as CommonMilestonesService } from 'src/app/_interface/dl-milestones.service';
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { MILESTONE_TASK_MAX_LENGTH } from 'src/app/app.constants';
import { HTTPStatus } from 'src/app/interceptors/loader.interceptor';

@Injectable({
  providedIn: 'root'
})
export class DlMilestonesService extends CommonMilestonesService {

  constructor(http: HttpClient,
              requestHeadersService: ReaquestHeadersService,
              environmentService: StaticEnvironmentService,
              private fb: UntypedFormBuilder,
              httpStatus: HTTPStatus) {
    super(http, requestHeadersService, environmentService, httpStatus);
  }

  createMilestoneGroupForm(formLength: number, addTask: boolean = true) {
    const task = this.createTaskForGroupForm();
    let group = this.fb.group({
      name: ['', [Validators.required]],
      existingId: [''],
      questTasks: this.fb.array([
      ])
    });
    group.patchValue({
      name: 'Task group ' + (formLength + 1)
    });

    if (addTask) {
      (group.get('questTasks') as UntypedFormArray).push(task);
    }

    return group;
  }

  createTaskForGroupForm() {
    return this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(MILESTONE_TASK_MAX_LENGTH), Validators.minLength(3)]],
      task: [''], //description, , [Validators.maxLength(MILESTONE_TASK_DESC_MAX_LENGTH)]
      searchActivity: [''],
      activities: [],
      activitiesIds: [],
      addedLink: [''],
      imageUrl: [null], //it is used when a media is uploaded and it has image. or screenshot from video
      videoUrl: [null], //it is used when a media is uploaded and it has video
      video: [null], //it is used for YOUTUBE URL object. not read so can be ignored. 
      embeddedVideo: [null], //it is used for YOUTUBE URL object
      linkUrl: [null],
      linkPreview: [null], //it is used when a link is added. it is object. 
      linkedQuest: [null],
      linkedQuestId: [null],
      edit: [true],
      existingId: [''],
      required: [true],
      activityRecordListPoints: [1],
      order: [1]
    })
  }
}
